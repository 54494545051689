/* Style for input fields and textarea */
.material-photo input,
.material-photo textarea,
.material-photo select {
    width: 100%;
    margin: 8px;
}

/* Style for form divs */
.material-photo form div {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

/* Style for input fields */
.material-photo input[type="text"],
.material-photo input[type="email"],
.material-photo input[type="tel"],
.material-photo input[type="datetime-local"],
.material-photo select {
    flex: 2;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: #fff8f8;
}

/* Style for the material photo container */
.material-photo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  
  /* Style for the image */
  .material-photo img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 10px;
    max-height: 300px;
    width: auto;
    display: block;
    margin: 0 auto;
  }
  
  /* Style for error message when no image is available */
  .material-photo p.no-image {
    color: #808080; /* Grey color for 'No image' text */
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  /* Style for the file input field */
  .material-photo input[type="file"] {
    margin-top: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    width: 100%;
    max-width: 250px;
  }
  
  /* Style for the 'Save Details' button */
  .material-photo button {
    background-color: #6ba06c;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
    width: 100%;
    max-width: 200px;
  }
  
  /* Style for button hover */
  .material-photo button:hover {
    background-color: #639664;
  }
  
  /* Style for button when disabled */
  .material-photo button:disabled,
  .material-photo button.disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
  }
  
/* Style for labels */
.material-photo label {
  margin-bottom: 5px; 
  margin-right: 20px;
}
  
  /* Style for file input container */
  .material-photo .file-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid #ccc; /* Grey border */
    border-radius: 8px; /* Rounded corners */
    padding: 10px;
    max-width: 300px;
    width: 100%;
    margin-top: 10px;
  }
  
  /* Style for the file name text */
  .material-photo .file-name {
    color: #808080; /* Light grey color for the file name */
    font-size: 14px;
    margin-right: 10px; /* Space between file name and button */
    flex-grow: 1; /* Allow the file name to take up remaining space */
  }
  
  /* Style for the custom 'Choose file' button */
  .material-photo .file-upload-btn {
    background-color: #6ba06c;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
    white-space: nowrap;
  }
  
  /* Button hover effect */
  .material-photo .file-upload-btn:hover {
    background-color: #639664;
  }
  
  /* Style for the custom button when disabled */
  .material-photo .file-upload-btn:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
  }

  /* Hide the default file input button */
    .material-photo input[type="file"] {
        display: none;
    }