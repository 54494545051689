.custom-node {
  position: relative;
  border: 1px solid #222;
  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center; /* Center the label vertically */
  width: 120px; /* Adjust the width of the node */
}

.node-label-container {
  display: flex;
}

.node-label {
  margin-right: 5px; /* Add small space between label and icon */
  font-size: 12px; /* Adjust font size if needed */
  line-height: 1;  /* Ensure consistent line height */
}

.plus-icon {
  cursor: pointer;
  margin-left: 5px;
  display: flex;
  align-items: center;
  padding: 0;
  height: 100%;
}

.plus-icon svg {
  display: block;
  margin: 0;  /* Remove any default margin */
  line-height: 1; /* Ensure no extra line-height is affecting the icon */
}

.node-list {
  position: absolute;
  top: 0;
  left: 100%; /* Position to the right of the node */
  margin-left: 20px; /* Spacing between the node and list */
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  display: flex; /* Flexbox layout */
  flex-direction: column; /* Stack items vertically */
  width: auto; /* Automatically adjust the width */
}

.node-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.node-list li {
  white-space: nowrap; /* Prevent line breaks */
  width: 100%; /* Ensure the list item takes full width */
  flex-grow: 1; /* Allow the item to grow with the content */
  font-size: 12px;
}