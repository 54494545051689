.scope-emission-graph {
    padding: 20px 40px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin: 20px 0 20px;
    position: relative;
    margin-top: 30px;
}

.scope-emission-graph-header {
    display: flex;
    justify-content: space-between; /* Ensures content is on opposite ends */
    align-items: center;
    padding: 0; /* Adjust padding if needed */
    margin-top: 0; /* Removes default margin */
    color: #333;
    font-size: 12px;
    position: relative;
}

.scope-emission-graph-content {
    display: flex; /* Enables flexbox layout */
    justify-content: center; /* Centers content horizontally */
    align-items: center; /* Centers content vertically */
    height: 500px; /* Increased height */
    width: 100%;
}

.graph-wrapper {
    width: 95%; /* Adjusted to make the graph wider */
    max-width: 1000px; /* Increased the maximum width */
    height: 100%; /* Ensures it stretches vertically */
}

.selection-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: #333;
}

/* Dropdown label styling */
.selection-container label {
    margin-right: 10px;
    font-weight: bold;
}

/* Dropdown select styling */
.selection-container select {
    padding: 8px 12px;
    font-size: 14px;
    color: #495057;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f8f9fa;
    cursor: pointer;
    transition: border-color 0.3s ease, background-color 0.3s ease;
}

/* Hover and focus effects */
.selection-container select:hover {
    border-color: #45a049;
    background-color: #e6f4ea;
}

.selection-container select:focus {
    outline: none;
    border-color: #45a049;
    box-shadow: 0 0 4px rgba(69, 160, 73, 0.5);
}

/* Prediction container styling */
.prediction-container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 20px; /* Add spacing above the container */
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: #333;
}

/* Input styling */
.prediction-container input {
    padding: 8px 12px;
    font-size: 14px;
    color: #495057;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f8f9fa;
    width: 120px; /* Adjust width as needed */
    transition: border-color 0.3s ease, background-color 0.3s ease;
}

/* Input hover and focus effects */
.prediction-container input:hover {
    border-color: #45a049;
    background-color: #e6f4ea;
}

.prediction-container input:focus {
    outline: none;
    border-color: #45a049;
    box-shadow: 0 0 4px rgba(69, 160, 73, 0.5);
}

/* Button styling */
.prediction-container button {
    padding: 8px 16px;
    font-size: 14px;
    font-weight: bold;
    color: white;
    background-color: #4caf50;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* Button hover effect */
.prediction-container button:hover {
    background-color: #45a049;
}

/* Label styling for consistency */
.prediction-container label {
    font-size: 14px;
    color: #333;
}
