.filterContainer {
  position: relative;
}

.filterButton {
  font-size: 16px;
  background-color: transparent !important;
  margin-top: 0px !important;
  width: fit-content;
  color: grey;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.filterButton:hover {
  opacity: 0.5;
}

.dropdownContent {
  width: 500px;
  background-color: #fff;
  margin-top: 10px;
  padding: 20px;
  border-radius: 8px;
  border-bottom: 2px solid #c4c4c4;
  position: absolute;
  top: 100%;
  left: 80%;
  transform: translateX(-90%);
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 60px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}

.closeIcon {
  cursor: pointer;
  font-size: 18px;
  color: #8a8a8a;
  border-radius: 50%;
  padding: 3px;
  transition: background-color 0.5s;
}

.closeIcon:hover {
  background-color: #ededed;
}

.filtersContainer {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.filterItem {
  display: flex;
  flex-direction: column;
  width: calc(50% - 10px); 
}

.filterControl {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.selectInput {
  border: none;
  border-bottom: 2px solid #bfbfbf;
  padding: 5px;
  background: transparent;
  flex: 1;
  transition: border-bottom 0.3s;
  outline: none;
  font-size: 14px;
}

.selectInput:focus,
.selectInput:hover {
  border-bottom: 2px solid #3f3f3f;
}

.filterLabel {
  font-size: 13px;
  color: #787878;
}

.metricInput {
  width: 100px !important; 
  margin-top: 10px;
  font-size: 14px;
  border: none !important;
  border-radius: 0 !important;
  border-bottom: 2px solid #bfbfbf !important;
  background: transparent;
  transition: border-bottom 0.3s !important;
}

.metricInput:focus,
.metricInput:hover {
  border-bottom: 2px solid #3f3f3f;
}

.modalActions {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  gap: 10px;
}

.applyFiltersButton {
  background-color: #366699;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.applyFiltersButton:hover {
  opacity: 0.8;
}

.resetFiltersButton {
  background: none;
  color: #007bff;
  text-decoration: underline;
  border: none;
  cursor: pointer;
}

.resetFiltersButton:hover {
  background: none;
  text-decoration: underline;
  opacity: 0.8;
}

