.register-container {
    padding: 40px 60px;
    width: 500px;  /* Increase container width to accommodate wider input fields */
    margin: 50px auto;  /* Center the form vertically and horizontally */
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;  /* Center align the form content */
}

.form {
    width: 100%;  /* Make the form take full width of the container */
    max-width: 500px;  /* Set a max-width to prevent form from becoming too wide */
    padding: 20px;  /* Maintain padding */
    border-radius: 8px;  /* Optional: Add border radius for rounded corners */
}

.input-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;  /* Maintain spacing */
    width: 100%;  /* Make the input group take full width */
}

.input-field {
    flex: 1;
    margin-right: 20px;
    max-width: 45%;  /* Set a max-width to ensure it doesn't overflow */
}

.input-field-address {
    margin-bottom: 15px;
}

.input-field:last-child {
    margin-right: 0;
}

.input-field input {
    width: 100%;  /* Make input field take full width of the container */
    padding: 10px;  /* Maintain original height */
    font-size: 14px;  /* Keep the font size */
    border-radius: 5px;  /* Optional: Add border radius for rounded input boxes */
    border: 1px solid #ccc;  /* Optional: Add a border */
    box-sizing: border-box;  /* Ensure padding and border are included in the width */
}

.password-input-group {
    position: relative;
}

.toggle-password-visibility-button {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
}

.error-message {
    color: red;
    font-size: 12px;
    margin-top: 5px;
}
.register-button {
    width: 100%;  /* Keep the button full width */
    padding: 15px;
    font-size: 14px;
    background-color: #75b376;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
}

.register-button:hover {
    background-color: #6ca76d;
}

.form label {
    display: block;
    font-size: 12px;
    color: #4A4A4A;
    text-align: left;
  }