.terms-container {
    padding: 20px 40px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin: 20px auto;
    position: center;
    margin-top: 70px;
    width: 50%;
}

.terms-header {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.terms-content {
    margin-bottom: 20px;
    text-align: left; /* Align text to the left for better readability */
    padding: 20px 40px; /* Padding inside the content area */
    overflow: auto; /* Ensure overflow content is scrollable */
    max-height: 400px; /* Optional: set max height for content area */
}

.terms-buttons {
    display: flex;
    justify-content: center;
    gap: 20px; /* Space between buttons */
    margin-top: 20px;
}

.button-logout, .button-login {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
}

.button-logout {
    background-color: #b0bec5; /* Style the Decline button */
    color: white;
}

.button-login {
    background-color: #75b376; /* Style the Accept button */
    color: white;
}
