.dashboardSummaryContainer{
    display: flex;
    align-items:center;
    margin-bottom:20px;
    justify-content: space-between;
}

.barChartContainer {
    padding: 10px;
    width: 31%;
    height: fit-content;
    min-width: 220px;
    background-color:#fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 8px;
    position: relative;
    border:0.5px solid #e5e5e5;
}

.barChartTitle {
    margin-left: 10px;
    margin-top:10px;
    font-size: 14px;
    padding:5px;
    color:#7b7c7a;
    font-weight: bold;
}

.barChart{
    margin-left: 5px;
    margin-right: 5px;
}

.table-header{
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: black;
    font-weight: bold;
    font-size: 16px;
    transition: background-color 0.5s ease;
    padding: 5px;
    height: 60px;
}

.sectionTitle{
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;   
}

.sectionTitle:hover{
    color: grey;
}

.show_data_label_names {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin-top: 15px;
}

.resetButton{
    background-color: #6b9f6c;
    margin-left: 15px;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    width: fit-content;
    height: fit-content;
    border-radius: 15px;
    font-weight: bold;
    cursor: pointer;
    font-size: 14px;
    transition: opacity 0.5s ease;
}

.resetButton:hover {
    opacity: 0.5;
}

.content {
    padding: 0px 10px;
    overflow: hidden;
    height: 0;
    font-size: 14px;
    transition: height 0.3s ease-in-out;
    background-color: white;
    border-top: none;
    position: relative;
}

.content.open {
    height: 550px;
    width: 100%;
}

.content1.open {
    width: 100%;
    height: auto;
}

.graph-pair-container {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    margin: 5px;
}

.chart-options {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 10px;
    margin: 5px;
}

.graphs_block{
    width: 100%;
    height: 400px;
    position: relative;
}

.graphs_block .scatter-chart {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.download-button{
    position: absolute;
    left:-5%;
    border: none;
    width:40px;
    height:40px;
    padding: none;
    margin: none;
    border-radius: 50px;
    background-color: white;
    transition: background-color 0.5s ease;
}

.download-button:hover{
    background-color:#e7e7e7;;
}

.chart-legend-box {
    width: fit-content;
    border: 1px solid rgba(220, 220, 220, 0.1);
    border-radius: 5px;
    background-color: rgba(230, 230, 230, 0.3);
    flex-shrink: 0;
}


.point-container {
    padding: 2px;
    margin: 2px;
    align-items: center;
    justify-content: start;
    display: flex;
    flex-direction: row;
}

.chart-legend-box>div .point {
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

.chart-legend-box>div .text {
    margin-left: 8px;
    font-size: 13px;
    font-weight: bold;
    color: black;
    text-transform: uppercase;
}

.potentialReductionContainer{
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: calc(100% - 125px);
    margin-top: 10px;
    margin-bottom: 10px;
}

.potentialReductionTitle {
    font-size: 16px;
    font-weight: bold;
}

.potential-reductions{   
    text-align: left; 
    padding: 15px;
    border-radius: 5px;
    background-color: #f1f1f1;}

.potential-reductions-value {
    font-size: 18px;
    font-weight: bold;
}


.potential-reductions-value-positive {
    color: #28a745; 
}

.potential-reductions-value-negative {
    color: #dc3545; 
}

/* Priority Table CSS */
.assert-table {
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
}

.priority_table_container {
    height: auto;
    max-height: 350px;
    width: 98%;
    overflow: hidden;
    overflow-x: auto;
    overflow-y: scroll;
    border-radius: 5px;
    border: 3px solid #6b9f6c;
}

.priority_table_container::-webkit-scrollbar {
    width: 3px;
}

.priority_table_container::-webkit-scrollbar-thumb {
    background: #6b9f6c;
    border-radius: 3px;
    cursor: pointer;
}
.priority-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
    font-family: sans-serif;
}

.priority-table thead tr {
    background-color: #6b9f6c;
    color: #ffffff;
    text-align: left;
}

.priority-table th,
.priority-table td {
    padding: 12px 15px;
    
}

.priority-table tbody tr {
    border-bottom: 1px solid #ffffff;
    transition: background-color 0.5s ease;
}

.priority-table tbody tr:nth-of-type(even) {
    background-color: #f0f0f0;
}

.priority-table tbody tr:last-of-type {
    border-bottom: 2px solid #6b9f6c;
}

.priority-table tbody tr:hover {
    font-weight: bold;
    color: #009879;
    background-color: rgb(107, 159, 108, 0.3);
}


.tripEmissionsTableContainer {
    height: auto;
    max-height: 350px;
    width: 98%;
    overflow: hidden;
    overflow-x: auto;
    overflow-y: scroll;
    border-radius: 5px;
    border: 3px solid #6b9f6c;
}

.tripEmissionsTableContainer::-webkit-scrollbar {
    width: 3px;
}

.tripEmissionsTableContainer::-webkit-scrollbar-thumb {
    background: #6b9f6c;
    border-radius: 3px;
    cursor: pointer;
}

.tripEmissionsTable {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
    font-family: sans-serif;
}

.tripEmissionsTable thead tr {
    background-color: #6b9f6c;
    color: #ffffff;
    text-align: left;
}

.tripEmissionsTable thead tr:nth-child(1),
.tripEmissionsTable thead tr:nth-child(2) {
    background-color: #f0f0f0;
    color: #414141;
}

.tripEmissionsTable th,
.tripEmissionsTable td {
    padding: 12px 15px;
}

.tripEmissionsTable tbody tr {
    border-bottom: 1px solid #ffffff;
    transition: background-color 0.5s ease;
}

.tripEmissionsTable tbody tr:nth-of-type(even) {
    background-color: #f0f0f0;
}

.tripEmissionsTable tbody tr:last-of-type {
    border-bottom: 2px solid #6b9f6c;
}

.tripEmissionsTable tbody tr:hover {
    color: #009879;
    background-color: rgb(107, 159, 108, 0.3);
}

.exportButton,
.uploadButton {
    color: grey;
    cursor: pointer;
    transition: opacity 0.2s ease;
}

.exportButton:hover,
.uploadButton:hover {
    opacity: 0.5;
}

