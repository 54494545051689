.side-menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.336); 
    z-index: 999; 
}

.side-menu {
    position: fixed;
    top: 50px; 
    left: 0;
    width: 250px;
    height: calc(100% - 60px); /* Adjusted height to fit the bottom */
    padding: 20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); 
    background-color: rgb(255, 255, 255);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Pushes the content to top and bottom */
}

.side-menu ul {
    list-style-type: none;
    padding: 0;
    flex-grow: 1; /* Allows the menu items to take available space */
}

.side-menu li {
    margin-bottom: 10px;
}

.side-menu a {
    color: rgb(14, 13, 13);
    text-decoration: none;
    font-size: 18px;
}

.side-menu-link {
    display: flex;
    align-items: center;
    font-size: 18px; /* Ensure font size is consistent */
}

.side-menu-icon {
    margin-right: 8px; /* Adjust the spacing as needed */
    position: relative;
}

.logout-button {
    margin-bottom: 110px; /* Keeps the button at the bottom */
    background-color: #6ba06c; /* Set your preferred button color */
    color: white;
    border: none;
    padding: 8px;
    font-size: 12px;
    cursor: pointer;
    width: auto; /* Remove full width */
    margin-left: 50px; /* Adjust left margin */
    margin-right: 50px; /* Adjust right margin */
    text-align: center;
    border-radius: 5px;
}

.logout-button:hover {
    background-color: #639664; /* Darker blue on hover */
}

.nested-menu {
    padding-left: 20px; /* Indent nested menu items */
    list-style-type: disc; /* Add bullet points */
    margin-top: 10px; /* Add some space at the top of the nested list */
}

.nested-menu li {
    margin-left: 25px; /* Add some space to the left of nested list items */
}

.nested-menu .side-menu-link {
    font-size: 18px; /* Ensure font size is consistent for nested links */
}
