/* Existing styles */
.suppliers-settings {
    font-family: Arial, sans-serif;
    margin: 20px;
}

.container {
    max-width: 800px;
    margin: 0 auto;
}  

.header-and-controls {
    display: flex;
    justify-content: space-between; /* Distribute space between header and controls */
    align-items: flex-start; /* Align items to the start vertically */
    margin-bottom: 7px;
}

.header-text {
    display: flex;
    flex-direction: column; /* Stack heading and paragraph vertically */
    text-align: left;
}

.header-text h3 {
    margin: 0;
    font-size: 20px;
}

.header-text p {
    margin: 5px 0 0 0; /* Add some space below the heading */
    font-size: 14px;
    color: #666; /* Light gray color for paragraph text */
}

.dropdown-container {
    display: flex;
    align-items: center; /* Vertically center dropdown and button */
}

.user-dropdown {
    padding: 10px;
    font-size: 16px;
    margin-right: 10px;
    margin-top: -30px; /* Adjust this value to move it higher */
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 200px; /* Adjust as needed */
}


.add-button {
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #2a68ac;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: -30px; /* Adjust this value to move it higher */
}

.add-button:hover {
    background-color: #0056b3;
}

.suppliers-table{
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px; 
}
.utilizers-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px; 
}

.utilizers-text {
    text-align: left;
}

.suppliers-table th, 
.suppliers-table td,
.utilizers-table th, 
.utilizers-table td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: left;
}

.suppliers-table th,
.utilizers-table th {
    background-color: #f4f4f4;
}

.suppliers-table td,
.utilizers-table td {
    background-color: #fff;
}

.suppliers-table tr:nth-child(even),
.utilizers-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.suppliers-table tr:hover,
.utilizers-table tr:hover {
    background-color: #f1f1f1;
}

.header-and-controls {
    display: flex;
    align-items: center;
    height: 100%; /* Ensure that the height is fully used if necessary */
  }
  