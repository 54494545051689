.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    padding: 10px 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.header-left, .header-right {
    display: flex;
    align-items: center;
}

.header-right {
    padding-right: 20px; /* Add some padding to the right side */
}

.logo-link img {
    margin-right: 20px;
    padding: 10px;
}

.header-tab {
    margin-right: 15px;
    text-decoration: none;
    color: black;
    padding: 10px;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.header-tab:hover {
    background-color: #f0f0f0;
}

.header-right .header-tab:last-child {
    margin-right: 80px; /* Add margin to the right of the last tab */
}

.menu-icon {
    border: none;
    background: none;
    font-size: 20px;
    cursor: pointer;
    margin-right: 10px;
    margin-bottom: 7px;
}

.logout-button {
    background: none;
    border: none;
    cursor: pointer;
    color: gray;
    margin-right: 30px;
}

.header-tab.active-tab {
    font-weight: bold;
}
