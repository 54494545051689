.lca-container {
    padding: 20px 40px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin: 20px auto;
    position: relative;
    font-family: Arial, sans-serif;
}

.section {
    margin-bottom: 20px;
}

.section h2 {
    text-align: left; /* Aligns the title text to the left */
    margin: 0 0 10px 0; /* Adjusts margins to position the header neatly */
    padding: 0; /* Removes any default padding */
    font-size: 18px; /* Adjust as necessary for your layout */
    font-weight: bold; /* Ensures the headers stand out */
}

.subsection {
    background-color: #ffffff; /* Sets background color to pure white */
    color: black; /* Sets the text color to black */
    padding: 15px; /* Adds padding for spacing */
    border: 1px solid #ddd; /* Adds a light grey border */
    border-radius: 5px; /* Adds rounded corners */
    display: flex; /* Flexbox for layout */
    flex-direction: column; /* Aligns children vertically */
    gap: 10px; /* Adds spacing between child elements */
}

.subsection p {
    text-align: left;
    color: black;
}

.subsection .dropdown-selector {
    align-self: start;
    padding: 8px;
    font-size: 1em;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    color: #333;
    margin-top: 10px;
}

.readonly-ghg-textarea,
.editable-ghg-textarea {
    width: 100%;
    height: 100px;
    background-color: #f0f0f0;
    border: 1px solid #ddd; /* Changed to a lighter border for a softer look */
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: #333;
    line-height: 1.5;
    padding: 10px;
    resize: none;
    border-radius: 6px; /* Adds rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); /* Soft shadow for a subtle depth */
    transition: border 0.3s ease, box-shadow 0.3s ease; /* Smooth transition effect */
    box-sizing: border-box;
}

.editable-ghg-textarea {
    resize: vertical; /* Allows vertical resize */
}

.readonly-ghg-textarea:focus,
.editable-ghg-textarea:focus {
    border: 1px solid #ccc; /* Slightly darker border on focus */
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Enhanced shadow on focus */
    outline: none; /* Removes default outline */
}

.editable-ghg-textarea::-webkit-scrollbar {
    height: 8px;
    width: 8px;
}

.editable-ghg-textarea::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.editable-ghg-textarea::-webkit-scrollbar-thumb {
    background-color: #bfbfbf;
    border-radius: 10px;
    border: 2px solid #f1f1f1;
}

.editable-ghg-textarea::-webkit-scrollbar-thumb:hover {
    background-color: #999;
}

/* Custom scrollbar styling for the readonly-textarea */
.readonly-ghg-textarea::-webkit-scrollbar {
    height: 8px;
    width: 8px;
}

.readonly-ghg-textarea::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.readonly-ghg-textarea::-webkit-scrollbar-thumb {
    background-color: #bfbfbf;
    border-radius: 10px;
    border: 2px solid #f1f1f1;
}

.readonly-ghg-textarea::-webkit-scrollbar-thumb:hover {
    background-color: #999;
}