.toggle-switch {
  position: relative;
  display: flex;
  width: 150px;
  height: 40px;
  padding-right: 5px;
  padding-left: 4px;
  background-color: #F4F4F4;
  /* blue background */
  border-radius: 10px;
  cursor: pointer;
}

.toggle-option {
  flex: 1;
  text-align: center;
  line-height: 40px;
  /* centers text vertically */
  color: #666666;
  font-weight: bold;
  z-index: 1;
  /* Ensures text stays above the slider */
  user-select: none;
  /* Prevents text selection */
  transition: color 0.3s;
}

.toggle-option.selected {
  color: #666666;
  /* blue text for the selected option */
}

.toggle-slider {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 70px;
  height: 30px;
  background-color: #DEE9DE;
  /* white background for the slider */
  border-radius: 7px;
  transition: transform 0.3s ease;
  /* Smooth sliding animation */
  z-index: 0;
  /* Places slider behind the text */
}

.toggle-switch.supplier .toggle-slider {
  transform: translateX(0);
  /* Position slider on the left for 'Supplier' */
}

.toggle-switch.utilizer .toggle-slider {
  transform: translateX(75px);
  /* Position slider on the right for 'Utilizer' */
}

.circularity-container {
  padding: 20px 40px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin: 20px auto;
  position: center;
  text-align: left;
  font-size: 12px;
}

.circularity-page{
  padding-bottom: 90px;
}

.container-header-material {
  display: flex;
  justify-content: space-between; /* Ensures content is on opposite ends */
  align-items: center;
  padding: 0; /* Adjust padding if needed */
  margin-top: 0; /* Removes default margin */
  color: #333;
  font-size: 12px;
  position: relative;
}

.slider-row-container {
  display: flex;
  flex-wrap: wrap; /* Allows wrapping to the next row */
  justify-content: space-around;
  align-items: center; 
  gap: 12px;
}

.circularity-slider {
  flex: 1 1 30%; /* Adjust the width as needed */
  min-width: 150px; /* Minimum width to prevent too small sliders */
}

.save-button {
  background-color: #6BA06C; /* Green background */
  border: none; /* Remove borders */
  color: white; /* White text */
  padding: 15px 32px; /* Some padding */
  text-align: center; /* Centered text */
  text-decoration: none; /* Remove underline */
  display: inline-block; /* Make the button inline-block */
  font-size: 16px; /* Increase font size */
  margin: 4px 2px; /* Some margin */
  cursor: pointer; /* Pointer/hand icon */
  border-radius: 4px; /* Rounded corners */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}

.save-button:hover {
  background-color: #639664;
}

.circularity-toggle-switch {
  position: relative;
  display: flex;
  width: 300px; /* Adjusted for 4 options */
  height: 40px;
  background-color: #F4F4F4;
  border-radius: 10px;
  cursor: pointer;
  margin-left: auto; /* Pushes the switch to the right */
}

.circularity-toggle-switch .circularity-toggle-option {
  flex: 1;
  text-align: center;
  line-height: 40px;
  color: #666666;
  font-weight: bold;
  z-index: 1;
  user-select: none;
  transition: color 0.3s;
}

.circularity-toggle-switch .circularity-toggle-option.selected {
  color: #666666;
}

.circularity-toggle-switch .circularity-toggle-slider {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 65px; /* Adjusted width for 4 options */
  height: 30px;
  background-color: #DEE9DE;
  border-radius: 7px;
  transition: transform 0.3s ease;
  z-index: 0;
}

.circularity-toggle-switch.circularity-utility .circularity-toggle-slider {
  transform: translateX(0);
}

.circularity-toggle-switch.circularity-input .circularity-toggle-slider {
  transform: translateX(75px);
}

.circularity-toggle-switch.circularity-output .circularity-toggle-slider {
  transform: translateX(150px); /* Adjusted for 4 options */
}

.circularity-toggle-switch.circularity-end-of-life .circularity-toggle-slider {
  transform: translateX(225px); /* Adjusted for 4 options */
}

.header-toggle-container {
  display: flex;
  align-items: center; /* Vertically align the items */
  justify-content: space-between; /* Distribute space between items */
}

