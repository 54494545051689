.generateReportButton {
    background-color: #0056b3;
    font-weight: bold;
    color: white;
    border: none;
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
    opacity: 1 ;
    transition: opacity 0.5s ease;
    margin-bottom:  100px;
  }
  
  .generateReportButton:hover {
    opacity: 0.5;
  }
  
  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 75%;
    height: 70vh;
    overflow: hidden;
    z-index: 1000;
  }
  
  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 10px;
    font-size: 24px;
    font-weight: bold;
    padding: 10px;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
  }
  
  .tablist {
    list-style-type: none; 
    border-bottom: 1px solid #efefef;
    padding: 10px;
    display: flex; 
    align-items: center;
}

.tab {
    cursor: pointer;
    font-weight: bold;
    border-radius: 10px;
    padding: 8px 12px;
    margin-left: 15px;
    margin-right: 10px; 
    opacity: 0.4;
    background-color: #F5F5F5;
    transition: opacity 0.5s ease;
    border: none;
}
  
.selected {
    border: none;
    opacity: 1;
}

.tabDivider {
    margin: 0 10px;
    color: #efefef;
}
  
  .tabButton--selected {
    border-bottom: 2px solid #007bff;
  }
  
  .tabPanel {
    max-height: 35vh;
    overflow-y: auto;
    padding: 10px;
  }

  
  .footer {
    position: fixed;
    width: calc(100% - 40px);
    bottom: 10px;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  .generateReportActionButton {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    opacity: 1;
    font-weight: bold;
    transition: opacity 0.5s ease;
  }

  .generateReportActionButton:hover {
    opacity: 0.5;
  }

  .closeButton {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    opacity: 1;
    font-weight: bold;
    transition: opacity 0.5s ease;
  }
  
  .closeButton:hover {
    opacity: 0.5;
  }

  .navButton {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    opacity: 1;
    font-weight: bold;
    transition: opacity 0.5s ease;
  }

  .navButton:hover {
    opacity: 0.5;
  }

  .reportTitle {
    font-size: 18px;
    font-weight: bold;
  }
  


.checkboxSection {
  margin-bottom: 15px;
}

.checkboxSection label {
  display: flex;
  align-items: end;
  font-size: 16px;
}

.checkboxSection input[type="checkbox"] {
  margin-right: 10px;
  accent-color: orange;
}

section{
    margin-left: 5px;
    margin-top: 5px;
}
.partTitle {
  padding: 10px;
  font-size: 18px;

}

.table{
  margin: auto;
  width: 90%;
  border-collapse: collapse;
  margin-top: 10px;
  padding: 10px;
}

.projectTable  {
  margin: auto;
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  padding: 10px;
}

.projectTable thead th {
  background-color: #f2f2f2;
  text-align: left;
  padding: 10px;
  border: 0.5px solid #ddd;
}

.table thead th {
  background-color: #f2f2f2;
  text-align: left;
  padding: 10px;
  border: 0.5px solid #ddd;
}


.table td {
  width: 50%;
  font-weight: normal;
  padding: 5px ;
  text-align: left;
  font-size: 14px;
  border: 0.5px solid #cfcfcf;
}

.projectTable td {
  font-weight: normal;
  padding: 5px ;
  text-align: left;
  font-size: 14px;
  border: 0.5px solid #cfcfcf;
}

.label {
  font-weight: bold;
  text-align: right;
  padding-right: 10px;
  vertical-align: middle;
}

.nrCanFormInput {
  width: 100%;
  font-size: 14px;
  border: none !important;
  margin-bottom: 0px !important;
}

.buttonContainer {
  margin-top: 10px;
  display: flex;
  gap: 10px;
}

.reportContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 20px;
}

.nrCanTableInput {
  width: 100%;
  font-size: 14px;
  border: 1px solid #ddd; /* Added border for better visibility */
  box-sizing: border-box; /* Ensure padding is included in width */
}

.nrCanTableInputCell {
  width: 100px; 
  font-size: 14px;
}
