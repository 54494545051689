.table-div .process-env-container {
    display: flex;
    flex-wrap: wrap; /* Allow wrapping */
    justify-content: center;
    border-radius: 4px;
    margin: 20px auto;
    text-align: left;
}

.table-div .process-env-container > .process-table {
    flex: 1 1 100px; /* Flexible, but no smaller than 300px */
    min-width: 100px; /* Prevent the table from getting too small */
}

.table-div .process-env-container > .env-factor-table {
    flex: 2 1 100px; /* Flexible, but no smaller than 600px */
    min-width: 100px; /* Prevent the table from getting too small */
    overflow-x: auto; /* Handle overflow gracefully */
}

.table-div {
    background-color: rgb(247, 247, 247);
}

.supplier-utilizer {
    margin: 3%;
}

.inventory-date-container-supplier {
    display: flex;
    justify-content: center; /* Centers the button horizontally */
    align-items: center;
    padding-bottom: 100px;
}

.inventory-date {
    font-size: 16px;
    color: gray;
}

.generate-report-supplier-button {
    padding: 16px 32px;
    background-color: #6ba06c;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.generate-report-supplier-button:hover {
    background-color: #639664;
}

.life-cycle-assessment-div-sst{
    padding-bottom: 90px;
}
