.grid-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.grid-button:hover {
    background-color: #367c39;
}

/* Grid.css */
.grid-resizable-box {
    border: 0.1px solid #ececec;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
}

.ag-root,
.grid-resizable-box {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
}

.custom-resize-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: se-resize;
    background-color: transparent;
}

/* Grid scrollbar styling */
.grid-container ::-webkit-scrollbar {
    height: 8px; /* Adjust the height of the horizontal scrollbar */
    width: 8px; /* For vertical scrollbar */
}

.grid-container ::-webkit-scrollbar-track {
    background: #f1f1f1; /* Track background */
    border-radius: 10px; /* Rounding the track for a smoother look */
}

.grid-container ::-webkit-scrollbar-thumb {
    background-color: #bfbfbf; /* Scrollbar color */
    border-radius: 10px; /* Rounded edges for the scrollbar */
    border: 2px solid #f1f1f1; /* Padding around the scrollbar for a cleaner look */
}

.grid-container ::-webkit-scrollbar-thumb:hover {
    background-color: #999; /* Slightly darker on hover for interaction */
}

.ag-root-wrapper.ag-ltr.ag-layout-auto-height {
    border: none;
}

.ag-theme-alpine .ag-header {
    position: sticky;
    top: 0;
    z-index: 2; /* Ensure headers stay above row data */
    background-color: white; /* Ensure header background remains visible */
}

.ag-theme-alpine .ag-header-cell {
    background-color: #f0f0f0; /* Light grey background */
    color: #333; /* Darker text for contrast */
    font-weight: bold; /* Makes the text bold */
    border-bottom: 1px solid #ddd; /* Light border to separate headers */
}

.ag-center-aligned-header .ag-header-cell-label {
    justify-content: center !important; /* Center-aligns the header content */
}

.ag-theme-alpine {
    --ag-selected-row-background-color: rgb(0, 255, 0, 0.1);
}

/* Change the background color when hovering over rows */
.ag-theme-alpine .ag-row:hover {
    background-color: #e6f7e1 !important; /* Light green for hover */
}

.ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked::after {
    color: #6ba06c !important;
}

.ag-theme-alpine .ag-cell-focus {
    border: 1px solid rgb(117, 163, 117) !important;
}

/* Targeting the wrapper elements to change their focus styling with a transparent green */
.ag-wrapper.ag-input-wrapper:focus-within,
.ag-wrapper.ag-checkbox-input-wrapper:focus-within {
    outline: none; /* Remove the default outline */
    box-shadow: 0 0 0 2px rgba(0, 128, 0, 0.164); /* Apply a transparent green box shadow */
}

/* Additional styling to handle focus for inner input and checkbox elements with transparent green */
.ag-wrapper.ag-input-wrapper:focus-within .ag-input-field-input,
.ag-wrapper.ag-checkbox-input-wrapper:focus-within .ag-checkbox-input {
    outline: none; /* Remove the default outline from input elements */
    box-shadow: 0 0 0 2px rgba(134, 202, 134, 0.192); /* Transparent green focus shadow */
}

.ag-body-horizontal-scroll-container {
    height: 20px !important;
    max-height: 20px !important;
    min-height: 20px !important;
}

.ag-body-horizontal-scroll-viewport {
    height: 20px !important;
    max-height: 20px !important;
    min-height: 20px !important;
}



.ag-body-horizontal-scroll {
    height: 20px !important;
    max-height: 20px !important;
    min-height: 20px !important;
}

.ag-body-vertical-scroll {
    width: 20px !important;
    max-width: 20px !important;
    min-width: 20px !important;
}

.ag-body-vertical-scroll-viewport {
    width: 20px !important;
    max-width: 20px !important;
    min-width: 20px !important;
}

.ag-body-vertical-scroll-container {
    width: 20px !important;
    max-width: 20px !important;
    min-width: 20px !important;
}



