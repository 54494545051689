.feature-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 250PX;
  padding: 20px;
  margin: 20px;
  box-shadow: 0px 2px 4px rgba(0,0,0,0.25);
  border-radius: 8px;
  background-color: #fff;
}

.feature-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  color: #75b376;
}

h3 {
  margin: 5px 0;
  color: #2a2b2ae0;
}

p {
  text-align: center;
  color: #666;
}

.home-page {
  padding-top: 70px;
}