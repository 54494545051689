.settings {
    padding-top: 100px;
}

.settings-container {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    padding: 10px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.settings-navigation {
    width: 200px; 
    padding-right: 20px;
}

.settings-navigation nav ul {
    list-style-type: none;
    padding: 0;
}

.settings-navigation nav ul li {
    margin: 10px 0;
    margin-left: 20px;
}

.settings-navigation nav ul li a {
    display: block;
    padding: 10px 15px;
    background-color: #e9f3fd; 
    color: #6ba06c; 
    text-decoration: none;
    border-radius: 4px;
    font-weight: bold;
    font-size: 14px;
    transition: background-color 0.3s ease, color 0.3s ease;
    box-shadow: inset 0 0 0 1px #cce5ff; 
}

.settings-navigation nav ul li a:hover {
    background-color: #d9ecff; 
    color: #0056b3;
}


.suppliers-settings {
    flex-grow: 1;
    height: 500px; 
    margin-left: 20px;
}

