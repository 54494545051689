.download-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    display: none;
    /* Hide the icon by default */
}

.comparison-bar-chart:hover .download-icon,
.footprint-graph-container:hover .download-icon {
    display: block;
    /* Show the icon on hover */
}

.download-icon:hover {
    cursor: pointer;
    /* Show pointer cursor on hover */
}

.comparison-container {
    background-color: rgb(247, 247, 247);
    margin: 3%;
}

.dropdown-container {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.dropdown {
    flex-grow: 1;
    margin-right: 10px;
}

.dropdown:first-child {
    width: 20%;
}

.dropdown:nth-child(2) {
    width: 30%;
}

.dropdown:nth-child(3) {
    width: 30%;
    margin-right: 30px;
}

.dropdown:last-child {
    margin-right: 0;
}

.apply-button {
    padding: 8px 16px;
    background-color: #6ba06c;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
}

.apply-button:hover {
    background-color: #639664;
    /* Darker shade on hover for feedback */
}

.phase-cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: stretch;
    gap: 20px;
}

.comparison-bar-chart {
    padding: 20px 40px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin: 20px 0 20px;
    position: relative;
    margin-top: 30px;
}

.h2-comparison {
    text-align: center;
    margin-bottom: 20px;
    font-size: 20px;
}

.footprint-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    gap: 20px;
    height: 500px;
    /* Set equal height for both containers */
    padding-bottom: 60px;
}

.footprint_table-container,
.footprint-graph-container {
    flex: 1 1 50%;
    /* Ensure both take up equal space */
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 20px;
    position: relative;
    overflow: hidden;
    /* Prevent overflow outside the container */
    height: 100%;
    /* Full height to match parent container */
}

.footprint_table-container {
    overflow: auto;
}

.footprint-table-wrapper {
    overflow-x: auto;
    /* Scroll horizontally if the table is too wide */
    overflow-y: auto;
    /* Scroll vertically if the table is too tall */
}

.footprint-graph-container {
    overflow: auto;
    /* Allow scrolling for the graph if needed */
}

.footprint-table {
    min-width: 100%;
    border-collapse: collapse;
    font-size: 0.85em;
}

.footprint-table th,
.footprint-table td {
    padding: 8px;
    border: 1px solid #ddd;
}

.inventory-date-container-comparison {
    display: flex;
    justify-content: center;
    /* Centers the button horizontally */
    align-items: center;
    padding-bottom: 100px;
}

.inventory-date {
    font-size: 16px;
    color: gray;
}

.generate-report-button-comparison {
    padding: 16px 32px;
    background-color: #6ba06c;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.generate-report-button-comparison:hover {
    background-color: #639664;
    /* Darker shade on hover for feedback */
}

.units-info-comparison {
    color: #888;
    /* Grey text */
    font-size: 14px;
    margin-top: 20px;
    text-align: center;
}

/* Custom scrollbar styling for the footprint-table-wrapper */
.footprint-table-wrapper::-webkit-scrollbar {
    height: 8px;
    width: 8px;
}

.footprint-table-wrapper::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.footprint-table-wrapper::-webkit-scrollbar-thumb {
    background-color: #bfbfbf;
    border-radius: 10px;
    border: 2px solid #f1f1f1;
}

.footprint-table-wrapper::-webkit-scrollbar-thumb:hover {
    background-color: #999;
}


/* Custom scrollbar styling for the footprint-graph-container */
.footprint-graph-container::-webkit-scrollbar {
    height: 8px;
    width: 8px;
}

.footprint-graph-container::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.footprint-graph-container::-webkit-scrollbar-thumb {
    background-color: #bfbfbf;
    border-radius: 10px;
    border: 2px solid #f1f1f1;
}

.footprint-graph-container::-webkit-scrollbar-thumb:hover {
    background-color: #999;
}

/* Ensure individual options within the dropdown are also left-aligned */
.custom-select__option {
    text-align: left;
    background-color: white !important; /* Default background color */
    color: black; /* Default text color */
}

.custom-select__option--is-selected {
    background-color: #6ba06ca1 !important; /* Green when selected */
    color: white;
}

.custom-select__option--is-focused {
    background-color: #6ba06ca1 !important; /* Green on hover */
    color: white;
}

.custom-selecttype__option--is-selected {
    background-color: #6ba06ca1 !important; /* Green when selected */
    color: white;
}

.custom-selecttype__option--is-focused {
    background-color: #6ba06ca1 !important; /* Green on hover */
    color: white;
}

.custom-selecttype__control--is-focused {
    border-color: #6ba06c !important; /* Green border when focused */
    box-shadow: 0 0 0 1px #6ba06c !important; /* Optional subtle shadow */
}

.custom-select__control--is-focused {
    border-color: #6ba06c !important; /* Green border on focus */
    box-shadow: 0 0 0 1px #6ba06c !important; /* Optional subtle green shadow */
}

.comparison-page{
    margin-top: 90px;
}