.boundary-table-container {
    padding: 20px 40px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin: 20px auto;
    position: center;
    text-align: left;
    overflow: auto;
}

.toggle-button {
    background: none;
    border: none;
    cursor: pointer;
}

.header-content {
    margin: 0 10px; /* Add margin to create space between headers */
    padding: 2px 4px;
    font-weight: bold; /* Keep main headers bold */
}

/* Style for subheaders */
.header-content .subheader {
    font-weight: normal; /* Set subheader font-weight to normal */
}

th {
    white-space: nowrap;
    border: 1px solid #ccc;
    border-radius: 4px;
}

td {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 20px;
}

.horizontal-header .header-content {
    writing-mode: horizontal-tb !important;
    transform: rotate(180deg) !important;
}

.boundary-table {
    background-color: white;
    margin: 20px auto;
    padding: 20px;
    overflow: auto;
    width: 95%;
}

/* Custom scrollbar styling for the boundary-table-container */
.boundary-table-container::-webkit-scrollbar {
    height: 8px; 
    width: 8px;  
  }
  
  .boundary-table-container::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px; 
  }
  
  .boundary-table-container::-webkit-scrollbar-thumb {
    background-color: #bfbfbf; 
    border-radius: 10px; 
    border: 2px solid #f1f1f1; 
  }
  
  .boundary-table-container::-webkit-scrollbar-thumb:hover {
    background-color: #999; 
  }
  