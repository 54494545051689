.download-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    display: none; /* Hide the icon by default */
}

.reuse-graph-container:hover .download-icon {
    display: block; /* Show the icon on hover */
}

.download-icon:hover {
    cursor: pointer; /* Show pointer cursor on hover */
}

.grid-container-reuse {
    padding: 20px 40px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin: 20px auto;
    position: relative;
    margin-top: 90px; /* Adjust top margin if needed */
}

.reuse-graph-container {
    padding: 20px 40px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin: 20px auto;
    position: relative; 
}

.container-header {
    display: flex;
    text-align: left; /* Align text to the left */
    padding-left: 0px; /* Adds padding to separate text from the edge */
    margin-top: 0; /* Removes default margin */
    color: #333; /* Text color */
    font-size: 12px;
}

.toggle-button {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    margin-right: 10px;
}


.button-container-reuse {
    display: flex;
    justify-content: right; /* Aligns buttons to the left */
    margin-top: 20px; /* Adjust top margin if needed */
}

.button-container-reuse button {
    margin-right: 10px; /* Spacing between buttons */
    padding: 8px 16px; 
    background-color: #6ba06c; 
    color: white; 
    border: none; 
    border-radius: 4px; 
    cursor: pointer; 
    display: flex;
}

.button-container-reuse button:hover{
    background-color: #639664; /* Darker shade on hover for feedback */
}

.reuse{
    padding-bottom: 80px; 
    margin: 3%;
}

.custom-grid-class .ag-cell {
    text-align: left !important; /* Force left alignment */
}

.custom-grid-class .ag-header-cell {
    text-align: left !important; /* Force left alignment */
}

.custom-grid-class .ag-cell {
    padding: 8px; 
}

.custom-grid-class .ag-header-cell {
    padding: 10px; 
}

.inventory-date-container {
    display: flex;
    justify-content: center; /* Centers the button horizontally */
    align-items: center;
    padding-bottom: 100px;
}

.credits-total {
    display: flex;
    justify-content: flex-end; /* Aligns the text elements to the right */
    align-items: center; /* Vertically centers both elements */
    background-color: #f7f7f7;
    border-top: 1px solid #e0e0e0;
    width: 100%;
}

.credits-total-text {
    color: #4d4d4d;
    font-weight: 500;
    text-align: right; /* Aligns the text to the right */
    margin-left: 150px; /* Adds spacing between the text elements */
    margin-right: 5px; /* Adds spacing between the text elements */
}


.inventory-date-reuse {
    font-size: 16px;
    color: gray;
}

.generate-report-reuse-button {
    padding: 16px 32px;
    background-color: #6ba06c;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.generate-report-reuse-button:hover{
    background-color: #639664; /* Darker shade on hover for feedback */
}
