.document-viewer-container {
    padding: 20px;
    max-width: 100%;
    overflow-x: auto;
}

.document-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 16px;
    text-align: left;
    table-layout: auto; /* Allow columns to adjust based on content */
}

.document-table th,
.document-table td {
    border: 1px solid #ddd;
    padding: 10px;
}

.document-table th:first-child,
.document-table td:first-child {
    white-space: nowrap; /* Prevent text wrapping */
    width: 1%; /* Shrink column to fit content */
}

.document-table th {
    background-color: #f4f4f4;
    font-weight: bold;
}

.document-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.document-table tr:hover {
    background-color: #f1f1f1;
}

.references-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.references-list li {
    margin-bottom: 5px;
}

.document-link {
    margin-left: 10px;
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}

.document-link:hover {
    text-decoration: none;
}

.document-link:hover {
    text-decoration: none;
}

.document-upload-button,
.document-delete-button {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}

.document-upload-button:hover {
    background-color: #45a049;
}

.document-delete-button {
    background-color: #e53935;
}

.document-delete-button:hover {
    background-color: #d32f2f;
}

.document-delete-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}
