.lca-container {
    padding: 20px 40px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin: 20px auto;
    position: relative;
    font-family: Arial, sans-serif;
}

.section {
    margin-bottom: 20px;
}

.section h2 {
    text-align: left; /* Aligns the title text to the left */
    margin: 0 0 10px 0; /* Adjusts margins to position the header neatly */
    padding: 0; /* Removes any default padding */
    font-size: 18px; /* Adjust as necessary for your layout */
    font-weight: bold; /* Ensures the headers stand out */
}

.info-row {
    display: flex;
    /* Flex container for the row */
    align-items: center;
    /* Center items vertically */
    padding: 15px;
    margin-bottom: 0; /* Removes bottom margin to connect rows */
    background-color: #ffffff; /* Default background color */
}

.info-row:last-child {
    border-bottom: none; /* Remove border on the last row */
}

.info-row:nth-child(even) {
    background-color: #e0e0e0; /* Light grey shading for even rows */
}

.label {
    flex-basis: 10%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-right: 5px;
    font-size: 16px;
}

.general-info-label {
    flex-basis: 10%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-right: 5px;
    font-size: 16px;
}

.general-info-layout {
    margin-bottom: 20px;
}

.general-info-content {
    display: flex;
    flex-direction: column;
    gap: 1rem; /* Adds space between rows */
    margin-top: 1rem;
    padding: 10px;
    border: 1px solid #ddd; /* Optional: Adds a border around the section */
    border-radius: 5px; /* Optional: Rounds the corners of the container */
    background-color: #f9f9f9; /* Light background for contrast */
}

.general-info-header {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 20px; /* Adds space below the title and button */
}

.general-info-header .centered-title {
    text-align: center; /* Ensures the general info header title remains centered */
    margin: 0;
    padding: 0;
}



.value {
    flex-basis: 75%;
    text-align: left;
    padding-left: 10px;
}


.container-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.readonly-textarea,
.editable-textarea {
    width: 100%;
    height: 150px;
    background-color: #f0f0f0;
    border: 1px solid #ddd; /* Changed to a lighter border for a softer look */
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: #333;
    line-height: 1.5;
    padding: 10px;
    resize: none;
    border-radius: 6px; /* Adds rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); /* Soft shadow for a subtle depth */
    transition: border 0.3s ease, box-shadow 0.3s ease; /* Smooth transition effect */
    box-sizing: border-box;
}

.editable-textarea {
    background-color: #f0f0f0;
    resize: vertical; /* Allows vertical resize */
}

.readonly-textarea:focus,
.editable-textarea:focus {
    border: 1px solid #ccc; /* Slightly darker border on focus */
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Enhanced shadow on focus */
    outline: none; /* Removes default outline */
}

.centered-title {
    text-align: center;
    font-size: 18px;
    flex-grow: 1;
    /* Make the title take up available space */
}

.right-button {
    background-color: #6ba06c;
    color: white;
    border: none;
    padding: 8px 10px;
    cursor: pointer;
    font-size: 14px;
    border-radius: 5px;
    position: absolute;
    right: 0;
    /* Move button to the right edge */
}

.right-button:hover {
    background-color: #639664;
    /* Darker shade on hover for feedback */
}

/* Custom scrollbar styling for the editable-textarea */
.editable-textarea::-webkit-scrollbar {
    height: 8px;
    width: 8px;
}

.editable-textarea::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.editable-textarea::-webkit-scrollbar-thumb {
    background-color: #bfbfbf;
    border-radius: 10px;
    border: 2px solid #f1f1f1;
}

.editable-textarea::-webkit-scrollbar-thumb:hover {
    background-color: #999;
}

/* Custom scrollbar styling for the readonly-textarea */
.readonly-textarea::-webkit-scrollbar {
    height: 8px;
    width: 8px;
}

.readonly-textarea::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.readonly-textarea::-webkit-scrollbar-thumb {
    background-color: #bfbfbf;
    border-radius: 10px;
    border: 2px solid #f1f1f1;
}

.readonly-textarea::-webkit-scrollbar-thumb:hover {
    background-color: #999;
}

.small-textarea {
    min-height: 20px; /* Set a smaller minimum height */
    height: auto; /* Allow it to adjust if necessary */
    resize: vertical; /* Still allow vertical resizing */
}

.general-info-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
  }
  
  .row-light {
    background-color: #ffffff;
  }
  
  .row-dark {
    background-color: #f0f0f0;
  }

  
  