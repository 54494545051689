/* Style for input fields and textarea */
.additional-information input,
.additional-information textarea,
.additional-information select {
    width: 100%;
    margin: 8px;
}

/* Style for buttons */
.additional-information button {
    background-color: #6ba06c;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
}

.additional-information button:hover {
    background-color: #639664;
}

.additional-information button:disabled,
.additional-information button.disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
}

/* Style for headers */
.additional-information h3 {
    margin-bottom: 20px;
    text-align: center;
}

/* Style for form divs */
.additional-information form div {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

/* Style for labels */
.additional-information label {
    margin-bottom: 5px; 
    margin-right: 20px;
}

/* Style for input fields */
.additional-information input[type="text"],
.additional-information input[type="email"],
.additional-information input[type="tel"],
.additional-information input[type="datetime-local"],
.additional-information select {
    flex: 2;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: #fff8f8;
}
