.net-zero-toggle-switch {
  position: relative;
  display: flex;
  width: 300px;
  height: 40px;
  padding-right: 5px;
  padding-left: 4px;
  background-color: #F4F4F4;
  /* blue background */
  border-radius: 10px;
  cursor: pointer;
}

.net-zero-toggle-option {
  flex: 1;
  text-align: center;
  line-height: 40px;
  /* centers text vertically */
  color: #666666;
  font-weight: bold;
  z-index: 1;
  /* Ensures text stays above the slider */
  user-select: none;
  /* Prevents text selection */
  transition: color 0.3s;
}

.net-zero-toggle-option.selected {
  color: #666666;
  /* blue text for the selected option */
}

.net-zero-toggle-slider {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 100px;
  height: 30px;
  background-color: #DEE9DE;
  /* white background for the slider */
  border-radius: 7px;
  transition: transform 0.3s ease;
  /* Smooth sliding animation */
  z-index: 0;
  /* Places slider behind the text */
}

.net-zero-toggle-switch.valueChain .net-zero-toggle-slider {
  transform: translateX(0);
  /* Position slider on the left for 'Supplier' */
}

.net-zero-toggle-switch.organization .net-zero-toggle-slider {
  transform: translateX(100px);
  /* Position slider on the right for 'Utilizer' */
}

.net-zero-toggle-switch.corporate .net-zero-toggle-slider {
  transform: translateX(200px);
  /* Position slider on the right for 'Utilizer' */
}

.main-table-container-circularity {
  margin-top: 75px;
}

.material-container {
  padding: 20px 40px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin: 20px auto;
  position: center;
  text-align: left;
}

.netZero-page{
  padding-bottom: 90px;
}

.container-header-material {
  display: flex;
  justify-content: space-between; /* Ensures content is on opposite ends */
  align-items: center;
  padding: 0; /* Adjust padding if needed */
  margin-top: 0; /* Removes default margin */
  color: #333;
  font-size: 12px;
  position: relative;
}

.slider-row-container {
  display: flex;
  flex-wrap: wrap; /* Allows wrapping to the next row */
  justify-content: space-around;
  align-items: center;
  gap: 12px;
}

.circularity-slider {
  flex: 1 1 30%; /* Adjust the width as needed */
  min-width: 150px; /* Minimum width to prevent too small sliders */
}

.save-button {
  background-color: #6BA06C; /* Green background */
  border: none; /* Remove borders */
  color: white; /* White text */
  padding: 15px 32px; /* Some padding */
  text-align: center; /* Centered text */
  text-decoration: none; /* Remove underline */
  display: inline-block; /* Make the button inline-block */
  font-size: 16px; /* Increase font size */
  margin: 4px 2px; /* Some margin */
  cursor: pointer; /* Pointer/hand icon */
  border-radius: 4px; /* Rounded corners */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}

.save-button:hover {
  background-color: #69c06b; /* Darker green on hover */
}

.add-column-button, .add-content-button, .delete-column-button{
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
    color: #999; /* Lighter grey color */
    transition: color 0.3s ease; /* Smooth transition for hover effect */
}

.add-column-button:hover, .add-content-button:hover, .delete-column-button:hover{
    color: #555; /* Darker grey color on hover */
}

button .icon {
    vertical-align: middle; /* Slightly adjusts alignment */
}
  