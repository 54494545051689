.phase-card {
    margin-top: 15px;
    padding: 10px; /* Adjusted padding for better spacing */
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: center;
    width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Added shadow for depth */
}

.phase-title {
    font-size: 14px; /* Adjust font size */
    color: #888; /* Adjust color */
    margin-bottom: 10px;
}

.current-value {
    font-size: 20px; /* Adjust font size for emphasis */
    color: #111; /* Darker color for emphasis */
    font-weight: bold; /* Bold font */
}

.indicator {
    color: #31D411; /* Default green color */
    transform: rotate(180deg); /* Default rotation */
}

.average-value {
    font-size: 14px;
    color: #888;
    margin-top: 5px;
}

