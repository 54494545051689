.toggle-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
}

.chart-wrapper h3 {
    margin: 0 0 10px;
    font-size: 1.2em;
    text-align: center;
}

.charts-container {
    width: 100%;
    display: flex;
    /* Display items in a row */
    flex-direction: row;
    /* Align charts horizontally */
    justify-content: space-between;
    /* Space between charts */
    gap: 20px;
    /* Gap between the charts */
}

.chart-wrapper {
    width: 100%;
    min-width: 100px;
    padding: 20px 40px;
    /* Keep padding consistent */
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin: 20px auto;
    position: center;
}

.track-view-container {
    display: flex;
    flex-wrap: wrap;
    /* Allow wrapping */
    justify-content: center;
    border-radius: 4px;
    margin: 20px auto;
    text-align: left;
    overflow: auto;
}

.download-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    display: none;
    /* Hide the icon by default */
}

.reuse-graph-container:hover .download-icon {
    display: block;
    /* Show the icon on hover */
}

.download-icon:hover {
    cursor: pointer;
    /* Show pointer cursor on hover */
}

/* Custom scrollbar styling for the track-view-container */
.track-view-container::-webkit-scrollbar {
    height: 8px;
    width: 8px;
}

.track-view-container::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.track-view-container::-webkit-scrollbar-thumb {
    background-color: #bfbfbf;
    border-radius: 10px;
    border: 2px solid #f1f1f1;
}

.track-view-container::-webkit-scrollbar-thumb:hover {
    background-color: #999;
}