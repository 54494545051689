.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal content styles */
.modal-content {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  width: 400px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  position: relative;
}

/* Drag-and-drop area styles */
.dropzone {
  border: 2px dashed #ccc;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  margin-top: 20px;
}

/* Button styles */
.modal-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.modal-buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.modal-buttons button.upload-button {
  background-color: #4caf50;
  color: #fff;
}

.modal-buttons button.cancel-button {
  background-color: #f44336;
  color: #fff;
}

.modal-buttons button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}