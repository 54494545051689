.epd-table-container {
  padding: 20px 40px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin: 20px auto;
  position: center;
  text-align: left;
  overflow: auto;
  margin-top: 90px;
}

.dropdown-container {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.dropdown {
  flex-grow: 1;
  margin-right: 10px;
}

.dropdown label {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

.custom-select__control-epd {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  transition: border-color 0.3s;
}

.gwp-stats {
  display: flex;
  justify-content: flex-end;
  align-items: center; /* Vertically centers both elements */
  padding: 3px;
  background-color: #F7F7F7;
  border-top: 1px solid #f0f0f0;
  width: 100%;
  box-sizing: border-box;
}

.gwp-stats p {
  color: #4d4d4d;
  font-weight: 500;
  flex-grow: 1; /* Ensures the text grows to the center */
  text-align: right; /* Center the text in the middle */
  margin: 3px;
}