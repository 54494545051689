.table-div .process-env-container {
    display: flex;
    flex-wrap: wrap; /* Allow wrapping */
    justify-content: center;
    border-radius: 4px;
    margin: 20px auto;
    text-align: left;
}

.table-div .process-env-container > .process-table {
    flex: 1 1 100px;  /* Flexible, but no smaller than 300px */
    min-width: 100px; /* Prevent the table from getting too small */
}

.table-div .process-env-container > .env-factor-table {
    flex: 2 1 100px;  /* Flexible, but no smaller than 600px */
    min-width: 100px; /* Prevent the table from getting too small */
    overflow-x: auto; /* Handle overflow gracefully */
}

.table-div {
    background-color: rgb(247, 247, 247);
}

.organizationalorganization {
    margin: 3%;
}

.report-container {
    display: flex;
    align-items: center; /* Align items vertically */
    gap: 15px; /* Add space between the dropdown and button */
}

.report-type-dropdown {
    margin-left: 10px; /* Space between the dropdown and the button */
    padding: 6px 12px; /* Add padding to match the page's input fields */
    font-size: 14px; /* Match the font size of the page */
    border: 1px solid #ccc; /* Subtle border */
    border-radius: 4px; /* Smooth corners */
    background-color: #f8f9fa; /* Light background to blend with the page */
    color: #495057; /* Neutral text color */
    outline: none;
    cursor: pointer;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1); /* Slight shadow for depth */
}

.report-type-dropdown:hover {
     border-color: #45a049;
}

.report-type-dropdown:focus {
    border-color: #45a049;
    box-shadow: 0 0 4px rgba(69, 160, 73, 0.5);
}
