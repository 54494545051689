.table-div .process-env-container {
    display: flex;
    flex-wrap: wrap; /* Allow wrapping */
    justify-content: center;
    border-radius: 4px;
    margin: 20px auto;
    text-align: left;
}

.table-div .process-env-container > .process-table {
    flex: 1 1 100px;  /* Flexible, but no smaller than 300px */
    min-width: 100px; /* Prevent the table from getting too small */
}

.table-div .process-env-container > .env-factor-table {
    flex: 2 1 100px;  /* Flexible, but no smaller than 600px */
    min-width: 100px; /* Prevent the table from getting too small */
    overflow-x: auto; /* Handle overflow gracefully */
}


.table-div {
    background-color: rgb(247, 247, 247);
}

.supplychain {
    margin: 3%;
}
