.env-factor-table {
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin-left: 10px;
    position: relative;
    padding: 20px 40px;
    min-height: 20px; /* Set a specific height for the entire table container */
    display: flex;
    flex-direction: column;
}

.grid-container-env {
    display: flex;
    flex-direction: column;
    height: 100%; 
}

.container-header-envfactor {
    display: flex;
    justify-content: space-between; /* Ensures content is on opposite ends */
    align-items: center;
    padding: 0; /* Adjust padding if needed */
    margin-top: 0; /* Removes default margin */
    color: #333;
    font-size: 12px;
    position: relative;
}

.container-header-envfactor h2 {
    margin: 0; /* Removes default margin for the heading */
    margin-bottom: 20px; /* Space below the title */
    margin-top: 13px;
    text-align: left; /* Aligns text to the left */
    flex-grow: 1; /* Ensures the text occupies available space on the left */
}

.toggle-button-envfactor {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    position: absolute;
    right: 0; /* Moves the button to the far right */
}


.env-factor-grid-wrapper .ag-root {
    min-height: 260px;
}

.gwp-total-env {
    display: flex;
    justify-content: flex-end;
    padding: 3px;
    background-color: #f7f7f7;
    border-top: 1px solid #e0e0e0;
}

.gwp-biogenic-total-env-text {
    color: #4d4d4d;
    font-weight: 500;
    text-align: right; /* Aligns the text to the right */
    margin-left: 20px; /* Adds spacing between the text elements */
    margin-right: 10px;
}

.gwp-total-env-text{
    color: #4d4d4d;
    padding-right: 10px;
    font-weight: 500;
}



/* Grid scrollbar styling */
.grid-container-env ::-webkit-scrollbar {
    height: 8px; /* Adjust the height of the horizontal scrollbar */
    width: 8px;  /* For vertical scrollbar */
}

.grid-container-env ::-webkit-scrollbar-track {
    background: #f1f1f1; /* Track background */
    border-radius: 10px; /* Rounding the track for a smoother look */
}

.grid-container-env ::-webkit-scrollbar-thumb {
    background-color: #bfbfbf; /* Scrollbar color */
    border-radius: 10px; /* Rounded edges for the scrollbar */
    border: 2px solid #f1f1f1; /* Padding around the scrollbar for a cleaner look */
}

.grid-container-env ::-webkit-scrollbar-thumb:hover {
    background-color: #999; /* Slightly darker on hover for interaction */
}

.toggle-button-envfactor{
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.env-gwp-divider {
    padding: 0 8px; /* Adjust spacing as desired */
    color: #4d4d4d; /* Match the text color */
}

