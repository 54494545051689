.process-table {
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin-right: 10px;
    position: left;
    padding: 20px 40px;
}

.grid-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.ag-root {
    border: 1px solid #ccc; /* Style the entire grid */
}

.gwp-total {
    display: flex;
    justify-content: flex-end;
    align-items: center; /* Vertically centers both elements */
    padding: 3px;
    background-color: #f7f7f7;
    border-top: 1px solid #e0e0e0;
    width: 100%;
    box-sizing: border-box;
}

.gwp-total-text {
    color: #4d4d4d;
    font-weight: 500;
    flex-grow: 1; /* Ensures the text grows to the center */
    text-align: right; /* Center the text in the middle */
    margin-right: 10px;
}

.gwp-biogenic-total-text {
    color: #4d4d4d;
    font-weight: 500;
    padding-right: 10px;
    padding-left: 10px;
    text-align: right; /* Align the text to the right */
}

.gwp-total-number {
    color: #4d4d4d;
    font-weight: 500;
    padding-right: 10px;
    text-align: right; /* Align the number to the right */
}

.filter-bar {
    display: flex;
    justify-content: start;
    padding: 10px 10px;
    background-color: #f4f4f4;
    border-radius: 5px;
    overflow: auto;
    position: relative;
    margin-bottom: 5px;
}

.filter-bar .active-indicator {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 75%;
    background-color: #8cc08d36;
    border-radius: 5px;
    transition: left 0.3s ease, width 0.3s ease;
    z-index: 0;
}


.filter-bar button {
    padding: 4px 16px;
    border: none;
    background-color: transparent;
    color: #666;
    font-weight: bold;
    cursor: pointer;
    white-space: nowrap;
    position: relative;
    z-index: 1;
    transition: color 0.3s ease;
    border-right: 1px solid rgba(197, 197, 197, 0.5);
}

.filter-bar button:last-of-type {
    border-right: none !important;
}

.filter-bar button.active {
    color: #333;
}

.filter-bar button:hover {
    color: #333;
}

.filter-bar::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: white;
    border-radius: 5px;
    z-index: 0;
    transition: left 0.3s ease, width 0.3s ease;
}

/* Custom scrollbar styling for the filter-bar */
.filter-bar::-webkit-scrollbar {
    height: 8px;
    width: 8px;
}

.filter-bar::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.filter-bar::-webkit-scrollbar-thumb {
    background-color: #bfbfbf;
    border-radius: 10px;
    border: 2px solid #f1f1f1;
}

.filter-bar::-webkit-scrollbar-thumb:hover {
    background-color: #999;
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #09f;
    animation: spin 1s ease infinite;
    margin: 20px auto;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.container-header-process h2 {
    cursor: pointer;
    color: black; /* Default color */
    transition: color 0.3s ease; /* Smooth transition effect */
}

.container-header-process h2:hover {
    color: grey; /* Hover color */
}

.container-header-process {
    display: flex;
    justify-content: space-between;
    /* Ensures content is on opposite ends */
    align-items: center;
    padding: 0;
    /* Adjust padding if needed */
    margin-top: 0;
    /* Removes default margin */
    color: #333;
    font-size: 12px;
    position: relative;
  }
  
  .container-header-process h2 {
    margin: 0;
    /* Removes default margin for the heading */
    margin-bottom: 20px;
    /* Space below the title */
    margin-top: 13px;
    text-align: left;
    /* Aligns text to the left */
    flex-grow: 1;
    /* Ensures the text occupies available space on the left */
  }
  
  .gwp-divider {
    padding: 0 8px; /* Adjust spacing as desired */
    color: #4d4d4d; /* Match the text color */
}
