.download-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  display: none;
  /* Hide the icon by default */
}

.doughnut-chart:hover .download-icon {
  display: block;
  /* Show the icon on hover */
}

.footprint-barchart:hover .download-icon {
  display: block;
  /* Show the icon on hover within the footprint-barchart */
}

.download-icon:hover {
  cursor: pointer;
  /* Show pointer cursor on hover */
}

.doughnut-chart-wrapper {
  background-color: white;
  border: 1px solid white;
  border-radius: 4px;
  padding-top: 10px;
  padding-bottom: 10PX;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
}

.doughnut-chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  overflow: visible;
}

.footprint-container {
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-right: 10px;
  position: left;
  padding: 20px 40px;
  flex: 1 1 100px;
  /* Flexible, but no smaller than 300px */
  min-width: 100px;
  /* Prevent the table from getting too small */
}

.footprint-chart-container {
  display: flex;
  flex-wrap: wrap;
  /* Allow wrapping */
  justify-content: center;
  border-radius: 4px;
  margin: 20px auto;
  text-align: left;
}

.doughnut-chart {
  margin: 20px;
  padding: 20px;
  background-color: #44444400;
  border-radius: 4px;
  position: relative;
  width: 100%;
  /* Make the chart fit the width of its container */
  max-width: 100%;
  /* Prevent overflow beyond the container */
  height: auto;
  box-sizing: border-box;
  /* Include padding and border in the element's total width */
  overflow: visible
  /* Add scrollbar if the content exceeds the container */
}

.bar-chart {
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-left: 10px;
  position: relative;
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  flex: 2 1 100px;
  /* Flexible, but no smaller than 600px */
  min-width: 100px;
  /* Prevent the table from getting too small */
  overflow-x: auto;
  /* Handle overflow gracefully */
}

.container-header-envfootprint {
  display: flex;
  justify-content: space-between;
  /* Ensures content is on opposite ends */
  align-items: center;
  padding: 0;
  /* Adjust padding if needed */
  margin-top: 0;
  /* Removes default margin */
  color: #333;
  font-size: 12px;
  position: relative;
}

.container-header-envfootprint h2 {
  margin: 0;
  /* Removes default margin for the heading */
  margin-bottom: 20px;
  /* Space below the title */
  margin-top: 13px;
  text-align: left;
  /* Aligns text to the left */
  flex-grow: 1;
  /* Ensures the text occupies available space on the left */
}

.toggle-button-envfootprint {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  position: absolute;
  right: 0;
  /* Moves the button to the far right */
}

.container-header-footprint-overview {
  display: flex;
  justify-content: space-between;
  /* Ensures content is on opposite ends */
  align-items: center;
  padding: 0;
  /* Adjust padding if needed */
  margin-top: 0;
  /* Removes default margin */
  color: #333;
  font-size: 12px;
  position: relative;
}

.container-header-footprint-overview h2 {
  margin: 0;
  /* Removes default margin for the heading */
  margin-bottom: 20px;
  /* Space below the title */
  margin-top: 13px;
  text-align: left;
  /* Aligns text to the left */
  flex-grow: 1;
  /* Ensures the text occupies available space on the left */
}

.lca-stage-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  /* Add some space between the dropdown and the chart */
}

.lca-stage-select {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  background-color: white;
  appearance: none;
  /* Remove default dropdown arrow */
  margin-right: 30px;
  /* Add margin to move the select away from the download icon */
}

.bar-chart-content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.footprint-barchart {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* Aligns the select element to the right */
  position: relative;
  /* Position relative to allow absolute positioning of the download icon */
}

.footprint-doughnut {
  overflow: visible;
  width: 70% !important;
  height: 90% !important; /* Adjusted height to be smaller */
}

.scope-select-box {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  background-color: white;
  appearance: none;
  /* Remove default dropdown arrow */
  margin-right: 30px;
  /* Add margin to move the select away from the next element */
}

.legend-total-container {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  /* Ensure both containers have the same height */
  margin-top: 45px;
  align-items: center;
}


.gwp-total-footprint {
  flex: 0 0 35%;
  display: flex;
  flex-direction: row;
  /* Change to row to align items horizontally */
  align-items: center;
  /* Vertically center the content */
  justify-content: center;
  /* Align items to the left */
}

.vertical-divider {
  width: 2px;
  /* Thickness of the divider */
  background-color: #ccc;
  /* Color of the divider */
  height: 100%;
  /* Ensure it takes up the full height of the parent container */
  align-self: stretch;
  /* Make sure it stretches to the full height of its parent */
  margin: 0 15px;
  /* Space around the divider */
  height: auto;
}



.gwp-total-label-footprint {
  font-weight: bold;
  font-size: 15px;
  margin-right: 5px;
  /* Adds spacing between label and value */
}

.gwp-total-value-footprint {
  font-size: 16px;
  color: #555;
}

.legend {
  flex: 0 0 65%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* Align legend items at the top */
  align-items: flex-start;
  max-width: fit-content;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.legend-color {
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 10px;
  /* Adjusted the space between the color and the text */
  flex-shrink: 0;
  /* Ensures the circle maintains its size */
}

.legend-text {
  font-size: 16px;
  white-space: normal;
}

.units-info {
  color: #888;
  /* Grey text */
  font-size: 14px;
  margin-top: 20px;
  text-align: center;
}

.environmental-footprint-chart {
  max-height: 570px;
  margin-top: 10px;
}

/* Custom scrollbar styling for the doughnut chart container */
.doughnut-chart::-webkit-scrollbar {
  height: 8px;
}

.doughnut-chart::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.doughnut-chart::-webkit-scrollbar-thumb {
  background-color: #bfbfbf;
  border-radius: 10px;
  border: 2px solid #f1f1f1;
}

.doughnut-chart::-webkit-scrollbar-thumb:hover {
  background-color: #999;
}

.toggle-switch-footprint {
  position: relative;
  display: flex;
  width: 150px;
  height: 40px;
  padding-right: 2px;
  padding-left: 10px;
  background-color: #F4F4F4;
  border-radius: 10px;
  cursor: pointer;
}

.toggle-option-footprint {
  flex: 1;
  text-align: center;
  line-height: 40px;
  color: #666666;
  font-weight: bold;
  z-index: 1;
  user-select: none;
  transition: color 0.3s;
}

.toggle-option-footprint.selected {
  color: #666666;
}

.toggle-slider-footprint {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 70px;
  height: 30px;
  background-color: #DEE9DE;
  border-radius: 7px;
  transition: transform 0.3s ease;
  z-index: 0;
}

.toggle-switch-footprint.environmental .toggle-slider-footprint {
  transform: translateX(0);
  width: 91px;
}

.toggle-switch-footprint.material .toggle-slider-footprint {
  transform: translateX(94px);
  width: 55px;
}