.support {
    padding-top: 100px;
}

.support-container {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    padding: 10px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.support-navigation {
    width: 200px; 
    padding-right: 20px;
}

.support-navigation nav ul {
    list-style-type: none;
    padding: 0;
}

.support-navigation nav ul li {
    margin: 10px 0;
    margin-left: 20px;
}

.support-navigation nav ul li a {
    display: block;
    padding: 10px 15px;
    background-color: #e9f3fd; 
    color: #6ba06c; 
    text-decoration: none;
    border-radius: 4px;
    font-weight: bold;
    font-size: 14px;
    transition: background-color 0.3s ease, color 0.3s ease;
    box-shadow: inset 0 0 0 1px #cce5ff; 
}

.support-navigation nav ul li a:hover {
    background-color: #d9ecff; 
    color: #0056b3;
}


.pdf-viewer {
    flex-grow: 1;
    height: 500px; 
    margin-left: 20px;
    border: 1px solid #ccc;
}

/* Custom scrollbar styling for the rpv-core__inner-pages and rpv-core__inner-pages--vertical */
.rpv-core__inner-pages::-webkit-scrollbar,
.rpv-core__inner-pages--vertical::-webkit-scrollbar {
  height: 8px; 
  width: 8px;  
}

.rpv-core__inner-pages::-webkit-scrollbar-track,
.rpv-core__inner-pages--vertical::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 10px; 
}

.rpv-core__inner-pages::-webkit-scrollbar-thumb,
.rpv-core__inner-pages--vertical::-webkit-scrollbar-thumb {
  background-color: #bfbfbf; 
  border-radius: 10px; 
  border: 2px solid #f1f1f1; 
}

.rpv-core__inner-pages::-webkit-scrollbar-thumb:hover,
.rpv-core__inner-pages--vertical::-webkit-scrollbar-thumb:hover {
  background-color: #999; 
}

