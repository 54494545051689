.button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.button-container-left {
    margin-top: 20px; /* Space above the button */
    display: flex; /* Ensure buttons are arranged in a row */
    gap: 10px; /* Add 10px space between each button */
}

.button-container-right {
    margin-top: 20px; /* Space above the button */
    display: flex; /* Ensure buttons are arranged in a row */
    gap: 10px; /* Add 10px space between each button */
}

.button-container .delete-button {
    background-color: #ff4d4d;
}

.button-container .delete-button:hover {
     background-color: #d93b3b;
}

.button-container .delete-button:disabled {
    background-color: #b0bec5; /* Light gray background for disabled buttons */
    color: #78909c; /* Darker gray text to indicate non-interactivity */
    cursor: not-allowed; /* Cursor shows an action is not allowed */
}

.button-container .archive-button {
    background-color: #ffa500; /* Orange background for archive button */
}

.button-container .archive-button:hover {
    background-color: #e69500; /* Darker orange on hover */
}

.button-container .archive-button:disabled {
    background-color: #b0bec5; /* Light gray background for disabled buttons */
    color: #78909c; /* Darker gray text to indicate non-interactivity */
    cursor: not-allowed; /* Cursor shows an action is not allowed */
}

.button-container button {
    padding: 8px 16px; /* Padding inside the button for better touch */
    background-color: #6ba06c; /* Green background for better visibility */
    color: white; /* White text color */
    border: none; /* No borders */
    border-radius: 4px; /* Rounded corners for aesthetics */
    cursor: pointer; /* Cursor changes to pointer on hover */
}

.button-container button:hover {
    background-color: #639664; /* Darker shade on hover for feedback */
}

.button-container button:disabled {
    background-color: #b0bec5; /* Light gray background for disabled buttons */
    color: #78909c; /* Darker gray text to indicate non-interactivity */
    cursor: not-allowed; /* Cursor shows an action is not allowed */
}

.modal-footer .modal-confirm-button {
    background-color: #ff4d4d; /* Red background for confirm button */
    color: white; /* White text color */
    cursor: pointer; /* Regular pointer cursor */
}

.modal-footer .modal-confirm-button:hover {
    background-color: #e60000; /* Darker red on hover */
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1050;
}

.modal-content {
    background: #fff;
    padding: 20px;
    width: 300px; /* Adjust the width as needed */
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.modal-header {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
    justify-content: center;
    display: flex;
    color: #474849;
}

.modal-body {
    display: flex;
    flex-direction: column;
    gap: 10px;

}

.modal-footer {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.modal-close-button {
    background: none;
    border: none;
    cursor: pointer;
    color: #555;
    font-size: 16px;
    position: absolute;
    top: 10px;
    right: 10px;
}

.dropdown-style {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
}
