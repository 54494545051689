.tooltip-container {
    position: relative;
    display: inline-block;
}

.tooltip-message {
    visibility: hidden;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Position above the element */
    left: 50%;
    margin-left: -60px;
    width: 120px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip-message {
    visibility: visible;
    opacity: 1;
}
