.profile {
    padding-top: 100px;
}

.profile-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: flex-start; /* Align items at the top */
}

.profile-left {
    width: 100%;
}


.profile-section h2 {
    font-size: 18px;
    margin-bottom: 15px;
}

.input-group {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.input-group label {
    width: 120px; /* Fixed width for consistent alignment */
    font-size: 14px;
    margin-right: 10px;
    color: #333;
    text-align: left; /* Left-align the text */
}

.input-group input {
    flex: 1;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
}


.show-password-btn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    color: #000;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.company-logo-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo-placeholder {
    width: 150px;
    height: 150px;
    background-color: #eee;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.upload-btn {
    background-color: #6ba06c;
    color: #fff;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
}

.upload-btn:hover {
    background-color: #639664;
}
