
.collaborate input, 
.collaborate textarea {
    width: 100%;
    margin: 8px 0;
}

.collaborate form {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center form content horizontally */
}

.collaborate button {
    background-color: #6ba06c;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
    align-self: center; /* Ensure button is centered within the form */
}

.collaborate button:hover {
    background-color: #639664;
}

.collaborate h3 {
    margin-bottom: 20px;
    text-align: center;
}

.collaborate form div {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    width: 100%; /* Ensure the input fields take full width */
}

.collaborate label {
    margin-bottom: 5px; 
    margin-right: 20px;
    color: rgb(102, 102, 102);
    white-space: nowrap;
}

.collaborate input[type="text"],
.collaborate input[type="email"],
.collaborate input[type="tel"],
.collaborate input[type="datetime-local"] {
    flex: 2;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: #fff8f8;
}

.collaborate select {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Adds shadow for depth */
    background-color: rgb(247, 247, 247); /* Sets the background color */
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
}

.collaborate select:focus {
    border-color: #c0c0c0; /* Changes border color on focus */
    outline: none;
}

.collaborator-text {
    margin-top: 60px;
    text-align: center;
    color: #666;
}
