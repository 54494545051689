html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: rgb(247, 247, 247);
}

.login-page {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh; /* Ensure full viewport height */
}

.logo-side {
  width: 60%; /* Adjusted to 60% to balance with the 40% login container */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Ensure full height of the container */
}

.logo-container img {
  max-width: 50%; /* Reduce the image size */
  height: auto;
}

.login-container {
  width: 40%; /* Adjusted to 40% */
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  justify-content: center; /* Move content higher */
  align-items: center; /* Center content horizontally */
  padding: 0px 70px 0; /* Add top padding to move it higher */
  background-color: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  height: 100vh; /* Ensure full height of the container */
}

.login-header {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px; /* Adds space between the header and the form */
  color: #333; /* Adjust the color if needed */
}

.divider {
  border-bottom: 2px solid #75b376;
  width: 200px;
}

.form {
  width: 100%;
  max-width: 400px;
  margin: 0 auto; /* Center the form within the container */
}

.password-input-group {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
}

.form input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.password-input-group input {
  width: 100%;
  padding: 10px 40px 10px 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.toggle-password-visibility-button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #ccc;
}

.form button {
  width: 100%;
  background-color: #75b376;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 20px; /* Add some space before the register redirect */
}

.register-redirect {
  font-size: 12px;
  color: #4A4A4A;
  text-align: center; /* Center text */
}

.register-link {
  color: #73b173e0;
  text-decoration: underline;
  cursor: pointer;
}
